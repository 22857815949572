import { getProviders, getSession, signIn } from 'next-auth/react';
import { useEffect } from 'react';
import { localFileSource } from '../../helpers/FileHelper';


export default function SignIn({ providers }) {
  const auth0 = providers.auth0
  useEffect(() => {
    signIn(auth0.id)
  }, [])

  return (
    <></>
  );
}

export async function getServerSideProps(ctx) {
  const providers = await getProviders()
  const session = await getSession({ req: ctx.req });
  const redirectUrl = localFileSource("")


  if (session) {
    return {
      redirect: { destination: redirectUrl }
    }
  }
  return {
    props: {
      providers,
      redirectUrl,
    },
  }
}
